import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import * as olProj from 'ol/proj';
import * as olStyle from 'ol/style';
import { TetraService } from '@core/http-gen/services/tetra.service';
import { Observable } from 'rxjs';
import { Geometry } from 'ol/geom';

@Injectable({
    providedIn: 'root',
})
export class TetraSourceService {
    constructor(private tetraService: TetraService) {}

    getSource(): Observable<VectorSource> {
        // get posizioni tetra da ora a 1 ora fa
        return this.tetraService
            .apiTetraInformativaGet({
                da: Date.now() - this.hoursToMilliseconds(1),
                a: Date.now(),
            })
            .pipe(
                map((tetraPositions) => {
                    const tetraSource = new VectorSource<Geometry>({});
                    tetraPositions.forEach((tetra) => {
                        const tetraF = new Feature(new Point(olProj.fromLonLat(tetra.geography?.coordinates as [])));
                        const iconStyle = new olStyle.Style({
                            image: new olStyle.Icon({
                                anchor: [0.5, 0],
                                anchorOrigin: 'bottom-left',
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'pixels',
                                size: [32, 32],
                                opacity: 1,
                                src: 'assets/images/icons/vvf_marker.png',
                            }),
                            text: new olStyle.Text({
                                font: '14px Calibri,sans-serif',
                                fill: new olStyle.Fill({ color: '#000000' }),
                                stroke: new olStyle.Stroke({
                                    color: tetra.color,
                                    width: 2,
                                }),
                                text: tetra.issi?.toString(),
                            }),
                        });
                        tetraF.setStyle(iconStyle);
                        tetraSource.addFeature(tetraF);
                    });

                    return tetraSource;
                })
            );
    }

    private hoursToMilliseconds(hours: number): number {
        return hours * 60 * 60 * 1000;
    }
}
