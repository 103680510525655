<div
    class="position-absolute w-100 vh-100 m-0 p-0 top-0 overflow-hidden text-white"
    style="z-index: 2011; background-color: var(--dark-background); font-size: 16px !important"
>
    <!-- Intro to page -->
    <div class="position-absolute w-100 h-100 m-0 top-0 loadingBackground" style="z-index: 2100">
        <div class="row h-100 p-0 m-0">
            <div class="col-12 d-flex justify-content-center align-items-center loadingBox">
                <div class="loader mr-5"></div>
                <p class="m-0 p-0">FirePi</p>
            </div>
        </div>
    </div>

    <!-- Standby -->
    <div *ngIf="nightStandbyStatus" class="position-absolute w-100 h-100 m-0 top-0 nightStandbyBackground" style="z-index: 2020">
        <div class="row h-100 p-0 m-0">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                <div class="nightStandbyText">
                    <h1 class="m-0 p-0">Standby Notturno</h1>
                    <h5 class="m-0 p-0">Riattivazione automatica in caso di allerta</h5>
                </div>
            </div>
        </div>
    </div>

    <!-- Page content -->
    <div *ngIf="pageContent" class="d-flex flex-row">
        <div class="left-panel">
            <div class="h-100 w-100 d-flex flex-column p-0 overflow-hidden justify-content-end">
                <!-- Event -->
                <div id="event" class="box {{ gravitaEvento || 'noGravityCode' }}">
                    <h1 class="my-1 p-0">
                        {{ this.infoSelettiva?.eventoTipologia }}
                    </h1>
                    <h3 class="my-1 p-0">
                        {{ this.infoSelettiva?.eventoDettaglio }}
                    </h3>
                </div>

                <!-- Info -->
                <div class="box box-margin-top">
                    <div class="row m-0 p-0">
                        <h4 class="w-100 m-0 p-0 text-center mb-2">EVENTO</h4>
                        <h5 class="col-sm-2 p-0">Orario</h5>
                        <h5 class="col-sm-10 p-0 fw-normal">
                            {{ this.getFormattedDate(this.infoSelettiva?.dataOra, true) }}
                            <span class="ms-2">
                                {{ " (" + this.getElapsedTimeFromNow(this.infoSelettiva?.dataOra) + ")" }}
                            </span>
                        </h5>
                        <h5 class="col-sm-2 p-0">Comune</h5>
                        <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.localizzazioneComune }}</h5>
                        <h5 class="col-sm-2 p-0">Frazione</h5>
                        <h5 class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.localizzazioneFrazione }}</h5>
                        <h5 class="col-sm-2 p-0">Indirizzo</h5>
                        <h5 class="col-sm-10 p-0 fw-normal">
                            <p class="m-0 p fw-normal">
                                {{ [this.infoSelettiva?.localizzazioneIndirizzo, this.infoSelettiva?.localizzazioneCivico].join(" ") }}
                            </p>
                            <p class="m-0 p fw-normal">
                                {{ this.infoSelettiva?.localizzazioneAltreInfo }}
                            </p>
                        </h5>
                        <h5 class="col-sm-2 p-0">Note</h5>
                        <h5 id="eventNote" class="col-sm-10 p-0 fw-normal">
                            <p *ngFor="let line of this.infoSelettiva?.eventoNote?.split('\n')" class="m-0 p fw-normal">
                                {{ line }}
                            </p>
                        </h5>
                    </div>
                    <div class="divider"></div>

                    <h4 class="w-100 m-0 p-0 text-center mb-2">RICHIEDENTE</h4>
                    <div class="row m-0 p-0">
                        <h5 class="col-sm-2 p-0">Nome</h5>
                        <h5 id="callerName" class="col-sm-10 p-0 fw-normal">
                            {{ [this.infoSelettiva?.chiamanteNome, this.infoSelettiva?.chiamanteCognome].join(" ") }}
                        </h5>
                        <h5 class="col-sm-2 p-0">Telefono</h5>
                        <h5 id="callerPhone" class="col-sm-10 p-0 fw-normal">{{ this.infoSelettiva?.chiamanteTelefono }}</h5>
                    </div>
                </div>

                <!-- Maps -->
                <div class="m-0 p-0 overflow-hidden flex-grow-1 box-margin-top" style="height: 400px">
                    <app-ol-map
                        *ngIf="this.infoSelettiva?.localizzazionePosizione?.x && this.infoSelettiva?.localizzazionePosizione?.y"
                        class="m-0 p-0 h-auto w-100"
                        [center]="[11, 46]"
                        [disableControls]="true"
                        (mapReady)="onMapReady($event)"
                        style="margin: 0"
                    ></app-ol-map>
                    <div
                        class="w-100 text-center align-items-center d-flex justify-content-center m-0 p-0 d-flex flex-column"
                        *ngIf="!this.infoSelettiva?.localizzazionePosizione?.x || !this.infoSelettiva?.localizzazionePosizione?.y"
                    >
                        <h3>No Data</h3>
                    </div>
                </div>

                <!-- Debug info -->
                <div class="box box-margin-top d-flex flex-row fw-bold">
                    <p class="m-0">
                        Status: <span class="fw-light {{ this.debugStatus }}">{{ this.debugStatus }}</span>
                    </p>
                    <p class="ml-3 my-0">
                        Last Update: <span class="fw-light">{{ this.getFormattedDate(this.debugLastUpdate, true) }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="right-panel">
            <div class="h-100 w-100 d-flex flex-column p-0 overflow-hidden">
                <!-- Clock -->
                <div id="clock" class="box text-center">
                    <h1 class="m-1 p-0" style="font-weight: 500">
                        {{ this.clock }}
                    </h1>
                </div>

                <!-- Answers -->
                <div id="answer" class="row m-0 p-0 box-margin-top flex-grow-1 overflow-hidden">
                    <div class="col p-0 text-white">
                        <div id="answerTitle" class="boxUtenti presentiBackground">
                            <h3 class="w-100 m-0 p-0 text-center">IN ARRIVO - {{ this.partecipantiCounter }}</h3>
                        </div>
                        <div
                            id="presentiListItem"
                            *ngFor="let partecipante of partecipanti; let i = index"
                            class="boxUtenti presentiBackground"
                        >
                            <h3 class="m-0 p-0">{{ partecipante.nome }} {{ partecipante.cognome }}</h3>
                            <h5 class="m-0 p-0 fw-normal">{{ getNameByType(partecipante.tipoPartecipante) }}</h5>
                        </div>
                    </div>
                    <div class="col p-0 text-white">
                        <div id="answerTitle" class="boxUtenti nonPresentiBackground">
                            <h3 class="w-100 m-0 p-0 text-center">NON ARRIVO - {{ this.nonPartecipantiCounter }}</h3>
                        </div>
                        <div id="nonPresentiListItem" *ngFor="let partecipante of nonPartecipanti" class="boxUtenti nonPresentiBackground">
                            <h3 class="m-0 p-0">{{ partecipante.nome }} {{ partecipante.cognome }}</h3>
                            <h5 class="m-0 p-0 fw-normal">{{ getNameByType(partecipante.tipoPartecipante) }}</h5>
                        </div>
                    </div>
                </div>

                <!-- Debug info -->
                <div class="box box-margin-top d-flex flex-row justify-content-end">
                    <p class="mr-3 my-0 fw-bold">Notifiche</p>
                    <p class="mr-3 my-0">Inviate: {{ this.infoSelettiva?.notifiche?.inviate }}</p>
                    <p class="mr-3 my-0">Ricevute: {{ this.infoSelettiva?.notifiche?.ricevute }}</p>
                    <p class="my-0">Lette: {{ this.infoSelettiva?.notifiche?.lette }}</p>
                </div>
            </div>
        </div>

        <!-- Error popup -->
        <div *ngIf="errorBox" class="position-absolute w-100 h-100 m-0 top-0 errorBackground" style="z-index: 2050">
            <div class="row h-100 p-0 m-0">
                <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                    <div class="errorBox">
                        <h1 class="m-0 p-0">{{ this.errorTitle }}</h1>
                        <h4 class="m-0 p-0">{{ this.errorDescription }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
